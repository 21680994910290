<template>
  <div>
    <el-dropdown>
      <span class="user">
        <span class="img-box">
          <img
            :src="
              $store.state.userInfo.avatar
                ? $store.state.userInfo.avatar
                : headImg
            "
          />
          <img
            class="vipIdent"
            :src="isVip ? vipImg : novipImg"
          />
        </span>
        <span class="hover">{{ $store.state.userInfo.username }}</span>
      </span>
      <el-dropdown-menu slot="dropdown">
        <div class="info" style="">
          <span class="info-title">
            <span class="img-box">
              <img
                :src="
                  $store.state.userInfo.avatar
                    ? $store.state.userInfo.avatar
                    : headImg
                "
              />
              <img
                class="vipIdent"
                :src="isVip ? vipImg : novipImg"
              />
            </span>
            <span class="name hover">
              <span class="clamp">{{ $store.state.userInfo.username }}</span>
            </span>
          </span>
          <div class="info-btn">
            <span @click="goMine(0)"
              ><i
                class="el-icon-user-solid"
                style="color: #f5394c"
                size="12"
              ></i
              >个人中心</span
            >
            <span @click="goMine(2)"
              ><i class="el-icon-s-tools" style="color: #cccccc" size="14"></i
              >设置</span
            >
          </div>
          <div class="info-out">
            <span @click="loginOut"
              ><i
                class="el-icon-switch-button"
                style="color: #888888"
                size="15"
              ></i
              >退出登录</span
            >
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { loginOut } from "@/api/login.js";
export default {
  data() {
    return {
      headImg: require("@/assets/mine/headimg.png"),
      vipImg: require("@/assets/mine/vip.png"),
      novipImg: require("@/assets/mine/novip.png"),
      // isVip:false
    };
  },
  computed:{
    isVip(){
      if(this.$store.state.userInfo && this.$store.state.userInfo.isVip ){
        return this.$store.state.userInfo.isVip?true :false
      }else{
        return false
      }
    }
  },
  methods: {
    loginOut() {
      loginOut().then((res) => {
        if (res.code == 0) {
          this.$message.success("退出成功");
          this.$store.commit("setIsLogin", false);
          this.$store.commit("setUserInfo", {});
          localStorage.removeItem("isLogin");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    goMine(type) {
      this.$router.push({
        path: "/mine",
        query: {
          activeIndex: type,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-dropdown {
  display: flex;
  align-items: center;
}
.user {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #444444;
}
.img-box {
  position: relative;
  margin-right: 3px;
  cursor: pointer;
  img {
    width: 36px;
    height: 36px;
  }
  .vipIdent {
    position: absolute;
    bottom: 5px;
    right: 3px;
    width: 10px;
    height: 8px;
  }
}
.info {
  display: flex;
  flex-flow: column;
  //   width: 350px;
  padding: 0 20px;
  &-title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }
  .name {
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #444444;
    margin-left: 15px;
  }
  &-btn {
    display: flex;
    margin-bottom: 24px;
    span {
      width: 150px;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      background: #f8f8f8;
      cursor: pointer;
    }
    span:first-child {
      color: #f5394c;
      background: #feebed;
      margin-right: 10px;
    }
  }
  &-out {
    text-align: center;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #444444;
    padding: 20px 0 10px;
    border-top: 1px solid #ebebeb;
    cursor: pointer;
  }
}
</style>
