/*
 * @Author: your name
 * @Date: 2021-08-15 17:05:33
 * @LastEditTime: 2021-08-15 18:38:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /share-h5/src/plugins/bus.js
 */
import Vue from "vue";
const Bus = new Vue();
export default Bus;
