/*
 * @Author: your name
 * @Date: 2021-08-10 13:30:56
 * @LastEditTime: 2021-10-09 20:02:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \share-h5\src\components\login\index.js
 */
import Vue from "vue";
import login from "./index.vue";

const PopupBox = Vue.extend(login);

login.install = function (data) {
  let instance = new PopupBox({
    data,
  }).$mount();

  document.body.appendChild(instance.$el);
  Vue.nextTick(() => {
    instance.show = true;
  });
};

export default login;
