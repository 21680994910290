/*
 * @Author: your name
 * @Date: 2021-08-03 20:37:43
 * @LastEditTime: 2023-03-28 21:23:55
 * @LastEditors: yao_wb
 * @Description: In User Settings Edit
 * @FilePath: \fl-pc\src\api\index.js
 */
import axios from "axios";
import { Message } from "element-ui";
import router from "@/router";
import store from "@/store";
// import login from "@/components/login";
// 记录和显示错误
function errorLog(error) {
  if (process.env.NODE_ENV === "development") {
    console.log(error);
  }
  Message({
    message: error.message,
    type: "error",
    duration: 5 * 1000,
  });
}

// var local = "http://47.52.58.23:1030";
const service = axios.create({
  baseURL: "/api",
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
});
// console.log(service.baseURL);

// 请求拦截
service.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("loginToken");
    config.headers["Authorization"] = token;
    return config;
  },
  (error) => {
    errorLog(error);
    return Promise.reject(error);
  }
);

// axios respone拦截器
service.interceptors.response.use(
  (response) => {
    console.log(response);
    const res = response.data;
    if (res.code == 401) {
      localStorage.removeItem("loginToken", null);
      localStorage.removeItem("isLogin", "");
      localStorage.removeItem("userInfo", null);
      store.commit("setIsLogin", false);
      store.commit("setUserInfo", null);
      Message({
        message: res.msg,
        type: "error",
        duration: 5 * 1000,
      });
      router.replace({
        path: "/",
      });
      return;
    }
    if (
      (res.code && res.code != 0) ||
      (res.return_status && res.return_status != "00")
    ) {
      Message({
        message: res.msg || res.return_char,
        type: "error",
        duration: 5 * 1000,
      });
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default {
  service,
};
