import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import login from "./components/login";

import "./assets/css/reset.less";
import * as filters from "@/utils/util.js";

import EventBus from "@/plugins/bus.js";
Vue.use(EventBus);
Vue.prototype.$EventBus = EventBus;

Vue.config.productionTip = false;

Vue.prototype.$login = login.install;

Vue.prototype.filters = filters;

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
