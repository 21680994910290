<template>
  <div>
    <el-dropdown
      class="phone-dropdown"
      @command="setCode"
      trigger="click"
      placement="bottom-start"
    >
      <span class="el-dropdown-code">
        {{ phoneAreaCode }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <div class="item-list">
          <el-dropdown-item
            :command="'+' + item.num"
            v-for="item in areaCode"
            :key="item.name"
          >
            <span class="item-num">+{{ item.num }}</span> {{ item.name }}
          </el-dropdown-item>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import areaCode from "@/static/areaCode.json";
export default {
  props: {
    phoneAreaCode: {
      type: String,
      default: "+86",
    },
  },
  data() {
    return {
      areaCode: areaCode,
    };
  },
  methods: {
    setCode(e) {
      this.$emit("setCode", e);
    },
  },
};
</script>
<style lang="less" scoped>
.phone-dropdown {
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: 1;
}
/deep/.el-dropdown-code {
  display: inline-block;
  width: 75px;
  line-height: 24px;
  box-sizing: border-box;
  border-right: 1px solid #eee;
  padding: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
/deep/.item-list {
  max-height: 230px !important;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  /*定义滚动条轨道 内阴影+圆角*/
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }
  /*定义滑块 内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #eeeeee;
  }
  .el-dropdown-menu__item {
    display: flex;
    align-items: center;
    color: #666;
    .item-num {
      display: flex;
      min-width: 30px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-right: 10px;
    }
    &:hover {
      color: #66b1ff;
      .item-num {
        color: #66b1ff !important;
      }
    }
  }
}
</style>
