<!--
 * @Author: your name
 * @Date: 2021-06-23 19:42:33
 * @LastEditTime: 2021-10-21 15:40:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \study-pc\components\home\title.vue
-->
<template>
  <div class="title-box">
    <div class="title-left">
      <div class="imgBox">
        <img :src="url" alt="" />
        <span class="title">{{ title }}</span>
      </div>
      <span class="hint">{{ hint }}</span>
    </div>
    <div v-if="rightShow" class="title-right" @click="goMore">
      查看更多
      <img src="@/assets/header/right.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    rightShow: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({}),
  created() {},
  methods: {
    goMore() {
      this.$emit("goMore");
    },
  },
};
</script>
<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 25px 0;
  .title-left {
    display: flex;
    align-items: flex-end;
    .imgBox {
      display: flex;
      align-items: center;
    }
    img {
      display: inline-block;
      width: 36px;
      height: 36px;
      margin-right: 10px;
      transform: translateY(3px);
    }
    .title {
      line-height: 25px;
      margin-right: 20px;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #333333;
    }
    .hint {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 25px;
    }
  }
  .title-right {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #666666;
    line-height: 25px;
    cursor: pointer;
    &:hover {
      color: #fb3a4e;
    }
    img {
      display: inline-block;
      width: 6px;
      height: 11px;
      margin-left: 10px;
    }
  }
}
</style>
