<template>
  <div class="home">
    <Header />
    <NavMenu />
    <Banner />
    <!-- 活动 -->
    <div class="mian-couser">
      <Active
        :activeList="activeList"
        :title="'活動'"
        :imgSrc="hdIg"
        :type="1"
      />
    </div>
    <div class="mian-couser">
      <Active
        :activeList="specialList"
        :title="'興趣班'"
        :imgSrc="xqbIg"
        :type="2"
      />
    </div>
    <div class="mian-couser">
      <Branch :list="branchList" />
    </div>
  </div>
</template>

<script>
import Header from "@/components/home/header.vue";
import NavMenu from "@/components/home/nav.vue";
import Banner from "@/components/home/banner.vue";
import Active from "@/components/home/active.vue";
import Branch from "@/components/home/branch.vue";

import { getActiveList, getClassList, getBranchList } from "@/api/home.js";
export default {
  name: "Home",
  components: { Header, NavMenu, Banner, Active, Branch },
  data() {
    return {
      activeList: [],
      specialList: [],
      branchList: [],
      hdIg: require("@/assets/header/hd.png"),
      xqbIg: require("@/assets/header/xqb.png"),
    };
  },
  created() {
    this.getList();
    console.log(this.$store);
  },
  methods: {
    getList() {
      //获取活动
      getActiveList({
        page: 1,
        limit: 8,
      }).then((res) => {
        if (res.code === 0) {
          this.activeList = res.data.list;
        }
      });
      //   // 兴趣班
      getClassList({
        page: 1,
        limit: 8,
      }).then((res) => {
        if (res.code === 0) {
          this.specialList = res.data.list;
        }
      });
      //   // 分会
      getBranchList({
        page: 1,
        limit: 8,
      }).then((res) => {
        if (res.code === 0) {
          this.branchList = res.data.list;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  padding-bottom: 80px;
}
.mian-couser {
  width: 1220px;
  margin: 0 auto;
  padding-top: 10px;
}
</style>
