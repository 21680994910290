<!--
 * @Author: your name
 * @Date: 2021-06-21 11:21:35
 * @LastEditTime: 2022-02-24 21:52:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \study-pc\components\login\index.vue
-->

<template>
  <div>
    <el-dialog
      width="460px"
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="show"
    >
      <div class="login">
        <div>
          <div class="close">
            <i
              class="el-icon-close"
              size="10"
              style="color: #ffffff"
              @click="closeLogin"
            />
          </div>
          <div class="login-form" v-if="loginType === 'login'">
            <div class="login-from-title">登录</div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="logonFrom"
              class="login-form-box"
            >
              <el-form-item prop="phone">
                <!-- <areaCode
                  @setCode="setCode"
                  :phoneAreaCode="phoneAreaCode"
                ></areaCode> -->
                <span class="tel-box">+852</span>
                <el-input
                  placeholder="请输入手机号码"
                  v-model="ruleForm.phone"
                  class="login-from-phone"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  placeholder="请输入密码"
                  v-model="ruleForm.password"
                  class="login-from-password"
                  type="password"
                >
                </el-input>
              </el-form-item>

              <el-form-item prop="code">
                <div style="display: flex">
                  <el-input
                    placeholder="请输入验证码"
                    v-model="ruleForm.code"
                    class="login-from-code"
                  >
                  </el-input>
                  <div @click="getCaptcha()" class="code">
                    <el-image
                      :src="captchaCode"
                      style="width: 116px"
                    ></el-image>
                  </div>
                </div>
              </el-form-item>

              <div class="no-login">
                <span @click="changeIslogin('register')"
                  >没有帐号？立即注册</span
                >
                <span @click="changeIslogin('forgetPass')">忘记密码？</span>
              </div>

              <div @click="login('logonFrom')">
                <el-button :loading="isClicklType" class="login-from-submit"
                  >立即登录</el-button
                >
              </div>
            </el-form>
          </div>

          <div class="login-form" v-if="loginType !== 'login'">
            <div class="login-from-title">
              {{ loginType == "forgetPass" ? "忘记密码" : "注册" }}
            </div>
            <el-form
              :model="registeForm"
              :rules="registerRules"
              ref="registeForm"
              class="login-form-box"
            >
              <el-form-item prop="phone">
                <!-- <areaCode
                  @setCode="setCode"
                  :phoneAreaCode="phoneAreaCode"
                ></areaCode> -->
                <span class="tel-box">+852</span>
                <el-input
                  placeholder="请输入手机号码"
                  v-model="registeForm.phone"
                  class="login-from-phone"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="code">
                <div style="display: flex; position: relative">
                  <el-input
                    placeholder="请输入验证码"
                    v-model="registeForm.code"
                    class="login-from-code1"
                  >
                  </el-input>
                  <div @click="sendSms" class="login-from-send-code">
                    {{ state.codeText }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  placeholder="请输入密码"
                  v-model="registeForm.password"
                  class="login-from-password"
                  type="password"
                >
                </el-input>
              </el-form-item>

              <el-form-item prop="newPassword">
                <el-input
                  placeholder="确认密码"
                  v-model="registeForm.newPassword"
                  class="login-from-password"
                  type="password"
                >
                </el-input>
              </el-form-item>
              <div @click="login('registeForm')">
                <el-button :loading="isClicklType" class="login-from-submit">{{
                  loginType == "forgetPass" ? "确定" : "完成注册"
                }}</el-button>
              </div>
              <div class="no-register">
                <span @click="changeIslogin('login')">已有帐号？立即登录</span>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import areaCode from "@/components/areaCode";
import {
  getCaptcha,
  getAuthLogin,
  getUserInfo,
  getCode,
  registerUser,
  updaqtePass,
} from "@/api/login.js";
import store from "../../store";
import throttle from "@/plugins/throttle";
export default {
  components: { areaCode },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        if (this.phoneAreaCode != "+86") {
          callback();
        } else {
          const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error("请输入正确的手机号"));
          }
        }
      }
    };
    var checkPass = (rule, value, callback) => {
      if (value !== this.registeForm.password) {
        return callback(new Error("两次密码输入不一致"));
      } else {
        callback();
      }
    };
    return {
      show: false,
      agreementFlag: true,
      captchaCode: "",
      loginType: localStorage.getItem("isLoginType")
        ? localStorage.getItem("isLoginType")
        : "login",
      ruleForm: {
        phone: "",
        password: "",
        code: "",
        key: "",
      },
      rules: {
        phone: [{ validator: checkPhone, trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },

      registeForm: {
        phone: "",
        code: "",
        password: "",
        newPassword: "",
      },
      registerRules: {
        phone: [{ validator: checkPhone, trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        newPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: checkPass, trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },

      state: {
        codeText: "获取验证码",
        time: 60,
      },
      phoneAreaCode: "+852",
      isClicklType: false,
    };
  },
  created() {
    // alert(this.ISLOGIN);
  },
  mounted() {
    const _this = this;
    this.$nextTick(() => {
      localStorage.removeItem("userInfo");
      _this.getCaptcha();
      console.log(this, store);
    });
  },
  methods: {
    getCaptcha() {
      getCaptcha().then((res) => {
        if (res.code == 0) {
          let image1path = res.data.image.replace(/[\r\n]/g, "");
          this.captchaCode = image1path.toString();
          this.ruleForm.key = res.data.key;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    closeLogin() {
      this.show = false;
    },
    setCode(e) {
      this.phoneAreaCode = e;
    },
    login(forname) {
      this.$refs[forname].validate((valid) => {
        if (valid) {
          this.isClicklType = true;
          let phone =
            this.loginType === "login"
              ? this.ruleForm.phone
              : this.registeForm.phone;
          console.log(phone, "phone");
          //   if (this.phoneAreaCode != "+86") {
          //     phone =
          //       this.phoneAreaCode + this.loginType === "login"
          //         ? this.ruleForm.phone
          //         : this.registeForm.phone;
          //   } else {
          //     phone =
          //       this.loginType === "login"
          //         ? this.ruleForm.phone
          //         : this.registeForm.phone;
          //   }
          if (this.loginType === "login") {
            let data = {
              captcha: this.ruleForm.code,
              username: phone,
              password: this.ruleForm.password,
              key: this.ruleForm.key,
            };
            getAuthLogin(data).then((res) => {
              if (res.code == 0) {
                localStorage.setItem("loginToken", res.data.access_token);
                getUserInfo().then((req) => {
                  if (req.code === 0) {
                    localStorage.setItem("isLogin", true);
                    localStorage.setItem("userInfo", JSON.stringify(req.data));
                    store.commit("setIsLogin", true);
                    store.commit("setUserInfo", req.data);

                    this.$message.success("登录成功");
                    setTimeout(() => {
                      this.closeLogin();
                      location.reload();
                    }, 1000);
                  }
                });
              }
            });
          } else {
            let data = {
              mobile: phone,
              password: this.registeForm.password,
              newPassword: this.registeForm.newPassword,
              code: this.registeForm.code,
            };
            if (this.loginType == "forgetPass") {
              updaqtePass(data).then((res) => {
                if (res.code == 0) {
                  this.$message.success("修改成功");
                  localStorage.setItem("isLoginType", "login");
                  this.loginType = "login";
                  this.$refs.registeForm.clearValidate();
                }
              });
            } else {
              registerUser(data).then((res) => {
                if (res.code == 0) {
                  this.$message.success("注册成功");
                  localStorage.setItem("isLoginType", "login");
                  this.loginType = "login";
                  this.$refs.registeForm.clearValidate();
                }
              });
            }
          }
          this.isClicklType = false;
        }
      });
    },
    resetData() {
      this.ruleForm = {
        phone: "",
        password: "",
        code: "",
        key: "",
      };
      this.registeForm = {
        phone: "",
        code: "",
        password: "",
        newPassword: "",
      };
      this.state = {
        codeText: "获取验证码",
        time: 60,
      };
      //   this.phoneAreaCode = "+86";
    },

    changeIslogin(type) {
      this.resetData();
      this.loginType = type;
      localStorage.setItem("isLoginType", type);
      setTimeout(() => {
        if (this.$refs.logonFrom && type == "login") {
          this.$refs.logonFrom.clearValidate();
        } else if (this.$refs.registeForm && type !== "login") {
          this.$refs.registeForm.clearValidate();
        }
      }, 100);
    },
    sendSms() {
      if (!this.registeForm.phone) {
        this.$message.warning("手机号不能为空");
        return;
      }
      if (this.state.codeText != "获取验证码") return;
      let phone = "";
      if (this.phoneAreaCode == "+86") {
        if (this.filters.phone(this.registeForm.phone)) {
          return;
        }
        phone = this.registeForm.phone;
      } else {
        phone = this.registeForm.phone;
      }

      let data = {
        mobile: phone,
        area: this.phoneAreaCode,
      };
      getCode(data).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success("验证码发送成功");
          throttle(async () => {
            this.state.time = 60;
            const verification = setInterval(() => {
              this.state.codeText = this.state.time + "s重新获取";
              this.state.time--;
              if (this.state.time < 0) {
                clearInterval(verification);
                this.state.codeText = "获取验证码";
              }
            }, 1000);
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  position: relative;
  border-radius: 16px;
  width: 460px;
  background: #fff;
  height: 440px;
  .close {
    position: absolute;
    padding: 0px 4px;
    background: #c6c6c6;
    border-radius: 50%;
    text-align: center;
    top: 10px;
    right: 10px;
    i {
      font-size: 10px;
    }

    // i {
    //   width: 10px;
    //   height: 10px;
    // }
  }
  &-from {
    &-title {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      padding-top: 35px;
    }

    &-submit {
      cursor: pointer;
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
      width: 400px;
      height: 40px;
      text-align: center;
      //   line-height: 40px;
      background: #f5394c;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 20px;
      border-radius: 24px;
      border: none;
    }
  }
  &-send {
  }
}
.login-from-send-code {
  margin: 0px 0 0 41px;
  display: flex;
  position: absolute;
  right: 10px;
  z-index: 100;
  cursor: pointer;
  color: #f5394c;
}
.login-form-box {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  width: 400px;

  .el-form-item__content {
    display: flex;
  }
  .tel-box {
    position: absolute;
    left: 10px;
    z-index: 999;
    margin-top: 5px;
    border-right: 1px solid #ccc;
    padding-right: 10px;
    height: 30px;
    line-height: 30px;
  }
  .login-from-phone {
    background: #f5f8fa;
    /deep/.el-input__inner {
      text-indent: 50px;
    }
  }
  .login-from-code {
    width: 275px;
    margin-right: 10px;
  }
  /deep/.el-input__inner {
    background: #f5f8fa;
  }
  .no-login {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #8399b2;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .no-register {
    cursor: pointer;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #8399b2;
    text-align: center;
    margin-top: 10px;
  }
  .code {
    width: 116px;
    height: 48px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/.el-close::before {
  color: #fff !important;
}
/deep/ .el-dialog__body {
  padding: 0;
}
</style>
