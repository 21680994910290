import axios from "./index.js";

export function getActiveList(params) {
  return axios.service({
    url: "/pc/activity/page",
    method: "get",
    params,
  });
}
export function getClassList(params) {
  return axios.service({
    url: "/pc/interestClass/page",
    method: "get",
    params,
  });
}

export function getBranchList(params) {
  return axios.service({
    url: "/pc/branch/page",
    method: "get",
    params,
  });
}

// 获取活动详情
export function getAcyiveId(id) {
  return axios.service({
    url: "/pc/activity/" + id,
    method: "get",
  });
}
export function getClassId(id) {
  return axios.service({
    url: "/pc/interestClass/" + id,
    method: "get",
  });
}

export function getBranchId(id) {
  return axios.service({
    url: "/pc/branch/" + id,
    method: "get",
  });
}

export function getBanner() {
  return axios.service({
    url: "/pc/advertising/list",
    method: "get",
  });
}

// 兴趣班订阅
export function getBubscriber(id) {
  return axios.service({
    url: "/pc/interestClass/subscriber/" + id,
    method: "get",
  });
}
