<template>
  <section class="hot-course">
    <msb-title title="分會" :url="url" @goMore="goMore" v-if="show"></msb-title>
    <div class="course" v-if="list.length > 0">
      <div
        class="single"
        v-for="(item, index) in list"
        :key="index"
        @click="record(item)"
      >
        <div class="padd">
          <img :src="item.poster" />
          <div>
            <div :title="item.name" class="courseName">
              {{ item.name }}
            </div>
            <div class="tips">
              <span>{{ item.activityNumber || 0 }} </span>活动
              <span>{{ item.interestClassNumber || 0 }}</span
              >兴趣班
            </div>
          </div>
        </div>
        <div class="content">
          {{ item.aboutUs }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MsbTitle from "./title";
export default {
  components: { MsbTitle },
  data() {
    return {
      url: require("@/assets/header/fh.png"),
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  created() {},
  methods: {
    record(item) {
      this.$router.push({
        path: "/branch",
        query: {
          id: item.id,
        },
      });
    },
    goMore() {
      this.$router.push({
        path: "/active",
        query: {
          type: 3,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.hot-course {
  padding-bottom: 10px;

  .flex {
    display: flex;
    justify-content: space-between;
  }
  .course {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    flex-wrap: wrap;
  }
  .single {
    padding: 30px;
    width: 380px;
    height: 245px;
    background: #ffffff;
    box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px 8px 8px 8px;
    margin-right: 29px;
    margin-top: 20px;
    position: relative;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:hover {
      transform: translateY(-10px);
      transition: all 0.6s;
    }
    &:hover::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0;
      background: url(../../assets/header/top-bg.png) no-repeat center;
      background-size: 100% 100%;
      height: 10px;
      width: 100%;
    }
    .padd {
      display: flex;
      img {
        width: 64px;
        height: 64px;
        background: #ffffff;
        margin-right: 15px;
        border: 1px solid #f6f6f6;
      }
      .courseName {
        text-align: left;
        font-size: 18px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .tips {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666c80;
        margin-top: 5px;
        span {
          color: #333333;
          font-size: 18px;
        }
        span:last-child {
          margin-left: 30px;
        }
      }
    }
    .content {
      margin-top: 25px;
      text-align: left;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #667280;
      line-height: 24px;
      width: 100%;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
  }
}
</style>
