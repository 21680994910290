<!--头部组件-->
<template>
  <div class="header">
    <div class="logo hover">
      <router-link to="/">
        <img src="@/assets/header/logo.png" alt="" />
      </router-link>
    </div>
    <div class="rg">
      <div class="search-box" @click="goSearch">
        <el-input
          suffix-icon="el-icon-search"
          placeholder="請輸入名稱"
        ></el-input>
      </div>
      <div class="news-box">
        <div class="admin-text" @click="loginAdmin">後臺管理登錄</div>
        <span class="user" v-if="!$store.state.isLogin">
          <span class="isLogin" @click="showLogin('login')">
            <span>登錄</span>
          </span>
          <span @click="showLogin('register')">
            <span>注册</span>
          </span>
        </span>
        <myDropdown v-else></myDropdown>
      </div>
    </div>
  </div>
</template>
<script>
import myDropdown from "@/components/header/my-dropdown.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    myDropdown,
  },
  data() {
    return {
      isLogin: false,
      fit: "cover",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
    };
  },
  mounted() {},
  methods: {
    ...mapMutations(["setUserInfo", "setIsLogin"]),
    closeLogin() {
      this.isLogin = false;
    },
    showLogin(type) {
      localStorage.setItem("isLoginType", type);
      this.$login();
    },
    loginAdmin() {
      window.open("https://admin.swa.org.hk/#/login");
    },
    repaly() {},
    goMessage() {},
    goSearch() {
      this.$router.push({
        path: "/search",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  height: 60px;
  padding-top: 10px;
  margin-bottom: 19px;
}

.logo {
  width: 198px;
  padding-top: 12px;
  margin-right: 260px;
  img {
    display: inline-block;
    width: 198px;
    height: 54px;
  }
}
.rg {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex: 1;
  .news-box {
    display: flex;
    align-items: center;
  }
  .admin-text {
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #899eb4;
    margin: 0 20px;
    cursor: pointer;
  }
  /deep/.el-input__inner {
    background: #f6f6f6 !important;
    border: none;
  }
  .search-box {
    position: relative;
    width: 400px;

    .search-btn {
      position: absolute;
      right: 0px;
      top: -4px;
      width: 50px;
      height: 38px;
      cursor: pointer;
    }
  }

  .news {
    margin-left: 50px;
    font-size: 20px;
    color: #cccccc;
    cursor: pointer;
    position: relative;
    .newsImg {
      width: 22px;
      height: 22px;
      //   background: url("@/assets/home/news.png");
      background-size: 100%;
    }
    .haveNewsImg {
      width: 22px;
      height: 22px;
      //   background: url("@/assets/home/news_a.png");
      background-size: 100%;
    }
    &:hover {
      .newsImg {
        width: 22px;
        height: 22px;
        // background: url("@/assets/home/news_a.png");
        background-size: 100%;
      }
    }
    .news-count {
      position: absolute;
      top: -10px;
      right: -12px;
      min-width: 18px;
      height: 18px;
      line-height: 18px;
      background: #fb3a4e;
      border-radius: 9px;
      color: #fff;
      font-size: 10px;
      text-align: center;
      padding: 0 5px;
    }
  }

  /deep/.el-input {
    width: 400px;
    border-radius: 20px;

    .el-input__inner {
      border-radius: 20px;

      &:focus {
        border-color: #fb3a4e;
      }
    }

    .el-input__suffix {
      right: 15px;
    }
  }

  /deep/ .el-input-group__append {
    border: 1px solid #fb3a4e;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fb3a4e;
    color: #ffffff;
  }

  .up {
    display: inline-block;
    height: 40px;
    vertical-align: sub;
  }

  .up-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    // padding-right: 7px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .up-text {
    vertical-align: bottom;
    font-size: 14px;
    color: #353535;
    line-height: 30px;
    cursor: pointer;
  }
  .user {
    display: flex;
    align-items: center;
    padding-left: 25px;
    cursor: pointer;

    .head {
      margin-right: 10px;
    }
    .img-box {
      display: flex;
      align-content: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid #fff;
      // overflow: hidden;
      &:hover {
        border: 2px solid rgb(233, 12, 48) !important;
      }
      position: relative;
      .vipIdent {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        border-radius: 0;
      }
    }
    > span {
      width: 72px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #ffffff;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #444444;
      border: 1px solid #b8b8b8;
      cursor: pointer;
      border-radius: 4px;
    }
    > span:last-child {
      background: #fb3a4e;
      color: #fff;
      border: none;
    }
    // span {
    //   &:hover {
    //     color: #3a83fb;
    //   }
    // }
    .isLogin {
      margin-right: 10px;
      color: #3a83fb;
    }

    .user-text {
      display: inline-block;
      max-width: 120px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-left: 12px;
      color: #333333;
      font-size: 14px;
      line-height: 30px;

      &:hover {
        color: rgb(233, 12, 48);
      }
    }
  }
}

.download-app {
  padding: 5px 10px 0 10px;

  img {
    display: inline-block;
    width: 150px;
    height: 150px;
  }

  &-hint {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    color: #999;
    font-size: 14px;
  }
}
</style>
