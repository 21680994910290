import Vue from "vue";
// 1.安装vuex包
// 2.导入vuex
import Vuex from "vuex";
Vue.use(Vuex);
// 4.定义规则并生成store对象
export default new Vuex.Store({
  state: {
    isLogin: localStorage.getItem("isLogin") || false,
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
  },
  mutations: {
    setIsLogin: (state, isLogin) => {
      if (!isLogin) {
        state.userInfo = {};
      }
      state.isLogin = isLogin;
    },
    setUserInfo: (state, userInfo) => {
      state.userInfo = userInfo;
    },
  },
  getters: {
    getIsLogin: (state) => {
      return state.isLogin;
    },
    getUserInfo: (state) => {
      return state.userInfo;
    },
  },
});
