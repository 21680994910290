<!--
 * @Date: 2023-03-28 21:01:51
 * @LastEditors: yao_wb
 * @LastEditTime: 2023-04-03 20:40:52
 * @FilePath: \fl-pc\src\App.vue
-->
<!--
 * @Date: 2023-03-28 21:01:51
 * @LastEditors: yao_wb
 * @LastEditTime: 2023-03-28 22:23:14
 * @FilePath: \fl-pc\src\App.vue
-->
<template>
  <div id="app">
    <div>
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/home/footer.vue";
export default {
  components: { Footer },
  mounted(){
    const arr = [
      [{id:1,name:'aa'},{id:2,name:'bb'}],
      [{id:1,name:'cc'},{id:2,name:'dd'}]
    ]
    // const a = this.test(arr)
    // console.log(a,'a')
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  //   padding-bottom: 190px;
  color: #2c3e50;
}
.hover {
  cursor: pointer;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
