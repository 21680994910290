<template>
  <section :class="isOther ? 'hot-course1' : 'hot-course'">
    <msb-title
      :title="title"
      :url="imgSrc"
      @goMore="goMore"
      v-if="show"
    ></msb-title>
    <div class="course" v-if="activeList.length > 0">
      <div
        :class="fullpath === 'active' ? 'single1' : 'single'"
        v-for="(item, index) in activeList"
        :key="index"
        @click="record(item)"
      >
        <div>
          <div class="s-img">
            <img :src="item.cover" />
          </div>
          <div class="padd">
            <div :title="item.title" class="courseName">
              {{ item.title }}
            </div>
            <div class="name-box">
              <div>{{ item.applyNumber || 0 }}人报名</div>
              <div class="price">
                <span v-if="item.price !== 0">$</span
                >{{ item.price == 0 ? "免费" : item.price }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MsbTitle from "./title";
export default {
  components: { MsbTitle },
  data() {
    return {
      url: require("@/assets/header/hd.png"),
    };
  },
  props: {
    activeList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    imgSrc: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: true,
    },
    type: {
      type: Number,
      default: 1,
    },
    fullpath: {
      type: String,
      default: "",
    },
    isOther: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  methods: {
    record(item) {
      console.log(item);
      this.$router.push({
        path: "/free",
        query: {
          type: this.type,
          id: item.id,
        },
      });
    },
    goMore() {
      this.$router.push({
        path: "/active",
        query: {
          type: this.type,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.hot-course {
  padding-bottom: 10px;

  .flex {
    display: flex;
    justify-content: space-between;
  }
  .course {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    flex-wrap: wrap;
    .single {
      width: 282px;
      border-radius: 8px;
      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.06);
      overflow: hidden;
      margin-right: 20px;
      margin-top: 20px;
      position: relative;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        transform: translateY(-10px);
        transition: all 0.6s;
      }
      .s-img {
        width: 100%;
        height: 167px;
        min-width: 224px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .single1 {
      width: 224px;
      border-radius: 8px;
      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.06);
      overflow: hidden;
      margin-right: 16px;
      margin-top: 20px;
      position: relative;
      &:nth-child(5) {
        // margin-right: 0;
      }
      &:hover {
        transform: translateY(-10px);
        transition: all 0.6s;
      }
      .s-img {
        width: 224px;
        height: 167px;
        min-width: 224px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .padd {
      padding: 10px 15px;
      box-sizing: border-box;
      text-align: left;
      .courseName {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 16px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        // line-height: 16px;
      }
      .name-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        margin-top: 42px;
      }
      .course-hint {
        position: absolute;
        top: 117px;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 50px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        width: 100%;
        padding: 11px 16px;
        box-sizing: border-box;
        font-size: 12px;
        line-height: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .price {
      font-size: 16px;
      font-family: Arial-Bold, Arial;
      font-weight: bold;
      color: #f5394c;
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
        margin-right: 3px;
      }
    }
  }
}

.hot-course1 {
  padding-bottom: 10px;

  .flex {
    display: flex;
    justify-content: space-between;
  }
  .course {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    flex-wrap: wrap;
    .single {
      width: 272px;
      border-radius: 8px;
      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.06);
      overflow: hidden;
      margin-right: 20px;
      margin-top: 20px;
      position: relative;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        transform: translateY(-10px);
        transition: all 0.6s;
      }
      .s-img {
        width: 100%;
        height: 167px;
        min-width: 224px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .single1 {
      width: 224px;
      border-radius: 8px;
      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.06);
      overflow: hidden;
      margin-right: 16px;
      margin-top: 20px;
      position: relative;
      &:nth-child(5) {
        // margin-right: 0;
      }
      &:hover {
        transform: translateY(-10px);
        transition: all 0.6s;
      }
      .s-img {
        width: 224px;
        height: 167px;
        min-width: 224px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .padd {
      padding: 10px 15px;
      box-sizing: border-box;
      text-align: left;
      .courseName {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 16px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        // line-height: 16px;
      }
      .name-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        margin-top: 42px;
      }
      .course-hint {
        position: absolute;
        top: 117px;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 50px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        width: 100%;
        padding: 11px 16px;
        box-sizing: border-box;
        font-size: 12px;
        line-height: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .price {
      font-size: 16px;
      font-family: Arial-Bold, Arial;
      font-weight: bold;
      color: #f5394c;
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
        margin-right: 3px;
      }
    }
  }
}
</style>
