import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/active",
    name: "Active",
    component: () => import("@/views/ActiveList"),
  },
  {
    path: "/free",
    name: "Free",
    component: () => import("@/views/ActiveDetais"),
  },
  {
    path: "/branch",
    name: "Branch",
    component: () => import("@/views/BranchDetais"),
  },
  {
    path: "/mine",
    name: "Mine",
    component: () => import("@/views/mine/index"),
    // children: [
    //   {
    //     path: "/orderdetails",
    //     name: "orderdetails",
    //     component: () => import("@/views/mine/orderdetails"),
    //   },
    // ],
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/search/index"),
  },
  {
    path: "/pay-success",
    name: "pay-success",
    component: () => import("@/views/paySuccess"),
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import("@/views/mine/about-us"),
  },
  {
    path: "/concat-us",
    name: "concat-us",
    component: () => import("@/views/mine/concat-us"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
