/*
 * @Author: your name
 * @Date: 2021-06-17 13:04:48
 * @LastEditTime: 2021-09-01 11:43:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \study-pc\utils\util.js
 */
import { MessageBox } from "element-ui";

import CryptoJS from "crypto-js";

//* * 加密路由参数 **
export const aesEncrypt = (message) => {
  const key = CryptoJS.enc.Utf8.parse("abcd@#0123456780");
  const ciphertext = CryptoJS.AES.encrypt(message, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return ciphertext.toString();
};

//* * 解密路由参数 **
export const aesDecrypt = (ciphertext, aesKey = "abcd@#0123456780") => {
  if (ciphertext) {
    const key = CryptoJS.enc.Utf8.parse(aesKey);
    const decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return CryptoJS.enc.Utf8.stringify(decrypted).toString();
  }
};

export function phone(phone) {
  return !/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(phone);
}
/*
	time:Number||1617948000000时间戳
	format：'yyyy-MM-dd HH:ss'时间显示格式
	(eg: dateFormat(timeA, 'yyyy-MM-dd HH:mm:ss') )
*/
// export function dateFormat(time, format = "yyyy-MM-dd HH:mm:ss") {
//   var t = new Date(time);
//   var tf = function (i) {
//     return (i < 10 ? "0" : "") + i;
//   };
//   return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
//     switch (a) {
//       case "yyyy":
//         return tf(t.getFullYear());
//         break;
//       case "MM":
//         return tf(t.getMonth() + 1);
//         break;
//       case "mm":
//         return tf(t.getMinutes());
//         break;
//       case "dd":
//         return tf(t.getDate());
//         break;
//       case "HH":
//         return tf(t.getHours());
//         break;
//       case "ss":
//         return tf(t.getSeconds());
//         break;
//     }
//   });
// }

export const secondsToHms = (value) => {
  var theTime = parseInt(value); // 秒
  var theTime1 = 0; // 分
  var theTime2 = 0; // 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
    }
  }
  var result = "" + parseInt(theTime) + "秒";
  if (theTime1 > 0) {
    result = "" + parseInt(theTime1) + "分" + result;
  }
  if (theTime2 > 0) {
    result = "" + parseInt(theTime2) + "小时" + result;
  }
  return result;
};
/* 获取倒计时 */
export const getSurplus = (startTime, endTime) => {
  let nowtime = new Date(startTime), //获取当前时间
    endtime = new Date(endTime); //定义结束时间
  let leftObj = {};
  leftObj["lefttime"] = endtime.getTime() - nowtime.getTime(); //距离结束时间的毫秒数
  leftObj["leftd"] = Math.floor(leftObj["lefttime"] / (1000 * 60 * 60 * 24)); //计算天数
  leftObj["lefth"] = Math.floor((leftObj["lefttime"] / (1000 * 60 * 60)) % 24); //计算小时数
  leftObj["leftm"] = Math.floor((leftObj["lefttime"] / (1000 * 60)) % 60); //计算分钟数
  leftObj["lefts"] = Math.floor((leftObj["lefttime"] / 1000) % 60); //计算秒数
  return leftObj;
};
/*
	开启倒计时,app对象必须有以下属性
	startTime: Number||1617948000000,	开始倒计时时间戳
	endTime: Number||1617948000000,		结束倒计时时间戳
	surplus: {},						接收时间对象
	timer: null,						计时器对象
 */
export const setSurplus = (app, callback) => {
  //   let timer = null;
  //获取倒计时相关属性
  app.surplus = getSurplus(app.startTime, app.endTime);
  //开启倒计时
  if (app.timer) {
    return false;
  }
  app.timer = setInterval(() => {
    app.startTime += 1000;
    app.surplus = getSurplus(app.startTime, app.endTime);
    //当倒计时为0时,停止计时器
    if (app.surplus.lefttime <= 0) {
      clearInterval(app.timer);
      // 执行回调函数
      typeof callback === "function" ? callback() : false;
    }
  }, 1000);
};
export function messageBox(info) {
  return MessageBox.confirm(info + ", 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  });
}

export function hashVal(val) {
  //  debugger
  var hash = 0,
    i,
    chr;
  if (val.length === 0) return hash;
  for (i = 0; i < val.length; i++) {
    chr = val.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

//emoji表情编码
export function utf16toEntities(str) {
  const patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
  str = str.replace(patt, (char) => {
    let H;
    let L;
    let code;
    let s;

    if (char.length === 2) {
      H = char.charCodeAt(0); // 取出高位
      L = char.charCodeAt(1); // 取出低位
      code = (H - 0xd800) * 0x400 + 0x10000 + L - 0xdc00; // 转换算法
      s = `&#${code};`;
    } else {
      s = char;
    }

    return s;
  });

  return str;
}
// 表情解码
export function entitiestoUtf16(strObj) {
  const patt = /&#\d+;/g;
  const arr = strObj.match(patt) || [];

  let H;
  let L;
  let code;

  for (let i = 0; i < arr.length; i += 1) {
    code = arr[i];
    code = code.replace("&#", "").replace(";", "");
    // 高位
    H = Math.floor((code - 0x10000) / 0x400) + 0xd800;
    // 低位
    L = ((code - 0x10000) % 0x400) + 0xdc00;
    code = `&#${code};`;
    const s = String.fromCharCode(H, L);
    strObj = strObj.replace(code, s);
  }
  return strObj;
}
export function editSize(size) {
  size = Number(size) || 0;
  if (size < 1024) {
    return size + "B";
  } else if (size < 1048576) {
    return (size / 1024).toFixed(2) + "KB";
  } else {
    return (size / 1048576).toFixed(2) + "M";
  }
}

// 下载文件图片
export function downloadFile(downloadUrl, fileName) {
  // const blob = new Blob([downloadUrl]);
  // 当文件名不存在时，截取/符号后的字符以及文件后缀当默认文件名称
  downloadUrl = downloadUrl.replace("http://msb-edu", "https://msb-edu");
  if (!fileName) {
    let pos = downloadUrl.lastIndexOf("/");
    fileName = downloadUrl.substring(pos + 1);
  }
  if ("download" in document.createElement("a")) {
    // 非IE下载
    const elink = document.createElement("a");
    elink.style.display = "none";
    // elink.href = downloadUrl;
    // elink.setAttribute('download',fileName)
    // document.body.appendChild(elink);
    // elink.click();
    // URL.revokeObjectURL(elink.href); // 释放URL 对象
    // document.body.removeChild(elink);
    fetch(downloadUrl)
      .then((res) => res.blob())
      .then((blob) => {
        // 将链接地址字符内容转变成blob地址
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        elink.setAttribute("download", fileName);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      });
  } else {
    // IE10+下载
    navigator.msSaveBlob(downloadUrl, fileName);
  }
}

export const Base64 = {
  table: [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "+",
    "/",
  ],
  UTF16ToUTF8: function (str) {
    var res = [],
      len = str.length;
    for (var i = 0; i < len; i++) {
      var code = str.charCodeAt(i);
      if (code > 0x0000 && code <= 0x007f) {
        // 单字节，这里并不考虑0x0000，因为它是空字节
        // U+00000000 – U+0000007F     0xxxxxxx
        res.push(str.charAt(i));
      } else if (code >= 0x0080 && code <= 0x07ff) {
        // 双字节
        // U+00000080 – U+000007FF     110xxxxx 10xxxxxx
        // 110xxxxx
        var byte1 = 0xc0 | ((code >> 6) & 0x1f);
        // 10xxxxxx
        var byte2 = 0x80 | (code & 0x3f);
        res.push(String.fromCharCode(byte1), String.fromCharCode(byte2));
      } else if (code >= 0x0800 && code <= 0xffff) {
        // 三字节
        // U+00000800 – U+0000FFFF     1110xxxx 10xxxxxx 10xxxxxx
        // 1110xxxx
        // var byte1 = 0xe0 | ((code >> 12) & 0x0f);
        // // 10xxxxxx
        // var byte2 = 0x80 | ((code >> 6) & 0x3f);
        // 10xxxxxx
        var byte3 = 0x80 | (code & 0x3f);
        res.push(
          String.fromCharCode(byte1),
          String.fromCharCode(byte2),
          String.fromCharCode(byte3)
        );
      } else if (code >= 0x00010000 && code <= 0x001fffff) {
        // 四字节
        // U+00010000 – U+001FFFFF     11110xxx 10xxxxxx 10xxxxxx 10xxxxxx
      } else if (code >= 0x00200000 && code <= 0x03ffffff) {
        // 五字节
        // U+00200000 – U+03FFFFFF     111110xx 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx
      } /** if (code >= 0x04000000 && code <= 0x7FFFFFFF)*/ else {
        // 六字节
        // U+04000000 – U+7FFFFFFF     1111110x 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx
      }
    }

    return res.join("");
  },
  //   UTF8ToUTF16: function (str) {
  //     var res = [],
  //       len = str.length;
  //     var i = 0;
  //     for (var i = 0; i < len; i++) {
  //       var code = str.charCodeAt(i);
  //       // 对第一个字节进行判断
  //       if (((code >> 7) & 0xff) == 0x0) {
  //         // 单字节
  //         // 0xxxxxxx
  //         res.push(str.charAt(i));
  //       } else if (((code >> 5) & 0xff) == 0x6) {
  //         // 双字节
  //         // 110xxxxx 10xxxxxx
  //         var code2 = str.charCodeAt(++i);
  //         var byte1 = (code & 0x1f) << 6;
  //         var byte2 = code2 & 0x3f;
  //         var utf16 = byte1 | byte2;
  //         res.push(Sting.fromCharCode(utf16));
  //       } else if (((code >> 4) & 0xff) == 0xe) {
  //         // 三字节
  //         // 1110xxxx 10xxxxxx 10xxxxxx
  //         var code2 = str.charCodeAt(++i);
  //         var code3 = str.charCodeAt(++i);
  //         var byte1 = (code << 4) | ((code2 >> 2) & 0x0f);
  //         var byte2 = ((code2 & 0x03) << 6) | (code3 & 0x3f);
  //         var utf16 = ((byte1 & 0x00ff) << 8) | byte2;
  //         res.push(String.fromCharCode(utf16));
  //       } else if (((code >> 3) & 0xff) == 0x1e) {
  //         // 四字节
  //         // 11110xxx 10xxxxxx 10xxxxxx 10xxxxxx
  //       } else if (((code >> 2) & 0xff) == 0x3e) {
  //         // 五字节
  //         // 111110xx 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx
  //       } /** if (((code >> 1) & 0xFF) == 0x7E)*/ else {
  //         // 六字节
  //         // 1111110x 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx
  //       }
  //     }

  //     return res.join("");
  //   },
  encode: function (str) {
    if (!str) {
      return "";
    }
    var utf8 = this.UTF16ToUTF8(str); // 转成UTF8
    var i = 0; // 遍历索引
    var len = utf8.length;
    var res = [];
    while (i < len) {
      var c1 = utf8.charCodeAt(i++) & 0xff;
      res.push(this.table[c1 >> 2]);
      // 需要补2个=
      if (i == len) {
        res.push(this.table[(c1 & 0x3) << 4]);
        res.push("==");
        break;
      }
      var c2 = utf8.charCodeAt(i++);
      // 需要补1个=
      if (i == len) {
        res.push(this.table[((c1 & 0x3) << 4) | ((c2 >> 4) & 0x0f)]);
        res.push(this.table[(c2 & 0x0f) << 2]);
        res.push("=");
        break;
      }
      var c3 = utf8.charCodeAt(i++);
      res.push(this.table[((c1 & 0x3) << 4) | ((c2 >> 4) & 0x0f)]);
      res.push(this.table[((c2 & 0x0f) << 2) | ((c3 & 0xc0) >> 6)]);
      res.push(this.table[c3 & 0x3f]);
    }

    return res.join("");
  },
  decode: function (str) {
    if (!str) {
      return "";
    }

    var len = str.length;
    var i = 0;
    var res = [];
    var code1, code2, code3, code4;
    var c1, c2, c3;
    while (i < len) {
      code1 = this.table.indexOf(str.charAt(i++));
      code2 = this.table.indexOf(str.charAt(i++));
      code3 = this.table.indexOf(str.charAt(i++));
      code4 = this.table.indexOf(str.charAt(i++));

      c1 = (code1 << 2) | (code2 >> 4);
      c2 = ((code2 & 0xf) << 4) | (code3 >> 2);
      c3 = ((code3 & 0x3) << 6) | code4;

      res.push(String.fromCharCode(c1));

      if (code3 != 64) {
        res.push(String.fromCharCode(c2));
      }
      if (code4 != 64) {
        res.push(String.fromCharCode(c3));
      }
    }

    return this.UTF8ToUTF16(res.join(""));
  },
};
