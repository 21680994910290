<template>
  <div class="banner">
    <el-carousel indicator-position="none">
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <img :src="item.picture" @click="openLink(item.link)" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getBanner } from "@/api/home.js";
export default {
  data() {
    return {
      imgList: [
        {
          imgsrc: require("@/assets/header/b1.png"),
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getBanner().then((res) => {
        if (res.code === 0) {
          this.imgList = res.data;
        }
      });
    },
    openLink(link) {
      const w = window.open("about:blank");
      w.location.href = link;
    },
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 420px;
  /deep/.el-carousel__container {
    height: 420px;
  }
  img {
    width: 100%;
    height: 420px;
    cursor: pointer;
  }
}
</style>
