import axios from "./index.js";

// 获取图形码
export function getCaptcha() {
  return axios.service({
    url: "/sys/pc/auth/captcha",
    method: "get",
  });
}

// 获取短信验证码
export function getCode(data) {
  return axios.service({
    url: "/sys/pc/auth/send/code",
    method: "post",
    data: data,
  });
}

// 手机号验证码登陆
export function phoneLogin(data) {
  return axios.service({
    url: "/sys/pc/auth/mobile",
    method: "post",
    data: data,
  });
}

// 账号密码登录
export function getAuthLogin(data) {
  return axios.service({
    url: "/sys/pc/auth/login",
    method: "post",
    data: data,
  });
}

// 退出登录
export function loginOut(data) {
  return axios.service({
    url: "/sys/pc/auth/logout",
    method: "post",
    data: data,
  });
}

// 注册
export function registerUser(data) {
  return axios.service({
    url: "/sys/pc/auth/register",
    method: "post",
    data: data,
  });
}

// 修改密码
export function updaqtePass(data) {
  return axios.service({
    url: "/sys/pc/auth/password",
    method: "post",
    data: data,
  });
}

export function getUserInfo() {
  return axios.service({
    url: "/sys/user/info",
    method: "get",
  });
}

export function updateUserInfo(data) {
  return axios.service({
    url: "/sys/pc/auth/update/user",
    method: "put",
    data: data,
  });
}
